import React from 'react'
import { Provider } from 'jotai'

// Import global stylesheet
import './src/styles/app.css'

// Wrap component tree with Jotai provider
export const wrapRootElement = ({ element }) => <Provider>{element}</Provider>

// Force a reload when new content is available
export const onServiceWorkerUpdateReady = () => window.location.reload(true)
